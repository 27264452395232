import React from "react";
import Ticker from 'react-ticker';

function Header() {
  return (
    <div className="banner">
          <Ticker speed={7} mode="smooth">
            {() => (
                  <div className="banner-container">
                    <p className="banner-message">We're sorry Canada. We really would love to provide you with premium wireless for just $15 a month but it's currently out of our control. Ryan has some thoughts, but please keep in mind not only is he not a government regulator, to the best of our knowledge, he's never even played one on TV.</p>
                  </div>
              )}
          </Ticker>

    </div>

  );
}

export default Header;

import React from "react";

function Footer() {
  return (
    <footer className="footer">
      <ul className="footer-links">
        <li><a href="https://www.mintmobile.com/plan-terms-and-conditions/">Plan Terms & Conditions</a></li>
        <li><a href="https://www.mintmobile.com/privacy-policy/">Privacy Policy</a></li>
        <li><a href="https://www.mintmobile.com/acceptable-use-policy/">Acceptable Use Policy</a></li>
        <li><a href="https://www.mintmobile.com/site-terms-of-use/">Site Terms of Use</a></li>
        <li><a href="https://www.mintmobile.com/911-and-e911-disclosure/">911 & E911 Disclosure</a></li>
        <li><a href="https://www.mintmobile.com/ca-mts/">Changes to California Prepaid MTS Surcharge</a></li>
        <li><a href="https://www.mintmobile.com/sitemap/">Sitemap</a></li>
      </ul>
    </footer>
  );
}

export default Footer;

import React, { useState } from 'react';
import ModalVideo from 'react-modal-video'
import thumbnail from '../images/Ryan-video-sample.jpg'


function RRVideo() {
    const [isOpen, setOpen] = useState(false);

    const dataLayerEvent = () => {
        window.dataLayer.push({
            event: "Video Interaction",
            videoName: "Welcome to Canadian Site"
        }) 
    }

    return (
        <div className="video-modal-container">
            <ModalVideo 
            channel='youtube' 
            isOpen={isOpen} 
            videoId='rZcb_UR_pQQ' 
            youtube={{autoplay:1,enablejsapi:1,loop:0,rel:0,showinfo:1,start:0,wmode:'transparent',theme:'dark',mute:0}} 
            onClose={() => setOpen(false)} />
            <img className="modal-thumbnail" src={thumbnail} alt="video thumbnail" onClick={()=> {
                    setOpen(true);
                    dataLayerEvent();
                }
            } />
        </div>
    )
}

export default RRVideo;

import React from "react";
import "./../App.scss";
import Layout from "./Layout";
import RRVideo from "./RRVideo";
import MintCanadaLogo from '../images/Mint-Canada-Logo.png';

function Home() {
  return (
    <div className="App">
      <Layout>
          <div className="homepageContainer">
            <img className="homepageContainer__logo" src={MintCanadaLogo} alt="mint mobile canada logo" />
            <h1>And now a few words from our acting VP of Canadian billboards</h1>

            <RRVideo />

            <div className="ctaContainer">
              <h2 className="ctaContainer__heading">Find your representative here</h2>
              <p className="ctaContainer__subText">And please be nice</p>
              <a className="a-btn" href="https://www.ourcommons.ca/Members/en" target="_blank" rel="noopener noreferrer" 
              data-elcat="CTA"
              data-elact="Click Here"
              data-ellab="View Canadian Members of Parliament"
              data-elloc="Hero>Click Here">Click Here</a>
            </div>
          </div>
          <div className="legalContainer">New activation &amp; 3-month plan req'd. Taxes &amp; fees extra. Addt'l restrictions apply.</div>
      </Layout>
    </div>
  );
}

export default Home;
